import React from 'react';
import { navigate, Link } from 'gatsby';
import SEO from '../components/seo';
import { Wrapper } from '../components/layout';
import {
  Typography as T,
  Card,
  Box,
  Flex,
  Button,
  Separator,
  Table,
} from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import ImgTick from '../images/ico-tick-primary.svg';

const { THead, TBody, TD } = Table;

const PriceCard = styled(Card)`
  ul {
    padding-left: 1rem;
  }
  li {
    line-height: 1.5;
  }
`;
const PriceTag = styled(T.P)`
  font-size: ${themeGet('fontSizes.s')};
  font-weight: 400;
  line-height: 1.1;
`;

const PriceTagLarge = (props) => <T.H3 fontWeight={400} {...props} />;

const PriceRemarks = (props) => <T.P mt="xs" fontSize="s" {...props} />;

const CardTitle = styled(T.H2)`
  font-size: ${(props) => props.theme.fontSizes.s};
  opacity: 0.8;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

const SectionTitle = (props) => <T.Subtitle1 fontSize="s" {...props} />;

const Tick = (props) => <img src={ImgTick} width="14px" height="14px" />;

const DummyCell = styled(TD)`
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
`;

const CenterCell = styled(TD)`
  text-align: center;
  border: 1px solid #c9ced1;
  padding: ${themeGet('space.m')};
`;

const LeadingCell = styled(TD)`
  border: 1px solid #c9ced1;
  padding: ${themeGet('space.m')};
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 0;
`;

const TR = styled(Table.TR)`
  border: 0;
`;

const GetButton = (props) => (
  <Button size="s" mt="m" onClick={() => navigate('/contact-us')} {...props} />
);

const renderHead = (label) => (
  <TR>
    <LeadingCell colSpan="5" fontWeight="bold">
      <strong>{label}</strong>
    </LeadingCell>
  </TR>
);

const renderRow = (label, cols) => {
  return (
    <TR>
      <LeadingCell>{label}</LeadingCell>
      {Array.isArray(cols)
        ? cols.map((c, i) => <CenterCell key={i}>{c}</CenterCell>)
        : cols}
    </TR>
  );
};

const Pricing = (props) => {
  return (
    <>
      <SEO lang="en" title="Teamwork App Pricing" />
      <Wrapper>
        <Box
          as="section"
          m="auto"
          mt="l"
          textAlign="center"
          maxWidth={[null, 720, 720, null]}
        >
          <T.H1>Flexible plans for any teams.</T.H1>
          <T.P>
            We understand how hard it is to start a new business, thus we're
            offering flexible plans that grow with the business.
          </T.P>
        </Box>
        <Box display={['none', 'block', null, null]}>
          <Table mt="l">
            <THead>
              <TR>
                <DummyCell as="th" />
                <CenterCell as="th" colSpan={3}>
                  Cloud
                </CenterCell>
                <CenterCell as="th">Self-managed</CenterCell>
              </TR>
              <TR>
                <LeadingCell as="th" />
                <CenterCell as="th">
                  <T.H5>Starter</T.H5>
                  <PriceTag>HK$12 / user / mth</PriceTag>
                  <GetButton>Get it</GetButton>
                </CenterCell>
                <CenterCell as="th">
                  <T.H5>Business</T.H5>
                  <PriceTag>HK$25 / user / mth</PriceTag>
                  <GetButton>Get it</GetButton>
                </CenterCell>
                <CenterCell as="th">
                  <T.H5>Enterprise</T.H5>
                  <PriceTag>HK$35 / user / mth</PriceTag>
                  <GetButton>Get it</GetButton>
                </CenterCell>
                <CenterCell as="th">
                  <T.H5>Private cloud / On-premise</T.H5>
                  <PriceTag>Volume pricing</PriceTag>
                  <GetButton>Contact us</GetButton>
                </CenterCell>
              </TR>
            </THead>
            {/* prettier-ignore */}
            <TBody>
            {renderHead('Fundamental')}
            {renderRow('User management', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('User directory', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Device management', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('TSL Data Encryption', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Export messages', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Send broadcast messages', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Push notifications', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Technical support', <CenterCell colSpan="5">24/7 Direct developer support</CenterCell>)}
            {renderHead('Core features')}
            {renderRow('Direct & Group chats', [<Tick />, <Tick />, <Tick />, <Tick />])}
            {renderRow('Widget-based Home', [null, <Tick />, <Tick />, <Tick />])}
            {renderRow('Form', [null, <Tick />, <Tick />, <Tick />])}
            {renderRow('Workflow', [null, <Tick />, <Tick />, <Tick />])}
            {renderRow('Drive', [null, '250MB / user', '1 GB / user', 'Contact us'])}
            {renderRow('Collect e-payment', [null, null,  <Tick />, <Tick />])}
            {renderHead('Integration')}
            {renderRow('API integration', [null, <Tick />, <Tick />, <Tick />])}
            {renderRow('LDAP/AD integration', [null, <Tick />,  <Tick />, <Tick />])}
            {renderHead('Compliance')}
            {renderRow('Password policy', [null, null, <Tick />, <Tick />])}
            {renderRow('Audit trail', [null, null, <Tick />, <Tick />])}
            {renderRow('Hardware device encryption', [null, null, <Tick />, <Tick />])}
          </TBody>
          </Table>
        </Box>
        <Box
          display={['flex', 'none', null, null]}
          flexDirection="column"
          alignItems="stretch"
        >
          <PriceCard m="m">
            <CardTitle>Starter</CardTitle>
            <PriceTagLarge>
              <strong>HK$12.00</strong>
            </PriceTagLarge>
            <PriceRemarks>per user per month</PriceRemarks>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>Direct & Group chats</li>
              <li>User management</li>
              <li>User directory</li>
              <li>Device management</li>
              <li>TSL data encryption</li>
              <li>Export messages</li>
              <li>Send broadcast messages</li>
              <li>Push notifications</li>
              <li>24/7 Direct developer support</li>
            </ul>
            <Button onClick={() => navigate('/contact-us')}>Contact us</Button>
          </PriceCard>

          <PriceCard m="m">
            <CardTitle>Business</CardTitle>
            <PriceTagLarge>
              <strong>HK$25.00</strong>
            </PriceTagLarge>
            <PriceRemarks>per user per month</PriceRemarks>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>
                <b>Includes everything in starter plan, plus</b>
              </li>
              <li>Widget-based Home</li>
              <li>Form</li>
              <li>Workflow</li>
              <li>250MB / user drive storage</li>
              <li>API integration</li>
              <li>LDAP/AD integration</li>
            </ul>
            <Button onClick={() => navigate('/contact-us')}>Contact us</Button>
          </PriceCard>
          <PriceCard m="m">
            <CardTitle>Enterprise</CardTitle>
            <PriceTagLarge>
              <strong>HK$35.00</strong>
            </PriceTagLarge>
            <PriceRemarks>per user per month</PriceRemarks>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>
                <b>Includes everything in Business plan, plus</b>
              </li>
              <li>Collecting e-payment</li>
            </ul>
            <SectionTitle mt={0}>Compliance features</SectionTitle>
            <ul>
              <li>Password policy</li>
              <li>Audit trail</li>
              <li>Hardware device encryption</li>
            </ul>
            <Button onClick={() => navigate('/contact-us')}>Contact us</Button>
          </PriceCard>
          <PriceCard m="m">
            <CardTitle>Private cloud / On-premise</CardTitle>
            <PriceTagLarge>Volume pricing</PriceTagLarge>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>
                <b>Includes everything in Enterprise plan, plus</b>
              </li>
              <li>Private cloud hosting / own your server scheme</li>
              <li>Optional 24/7 technical support</li>
              <li>Custom integration services</li>
            </ul>
            <Button onClick={() => navigate('/contact-us')}>Contact us</Button>
          </PriceCard>
        </Box>
        <T.P textAlign="center" mt="l">
          Schools and non-profit entities can enjoy up to <strong>50%</strong>{' '}
          discount on the subscription fee.{' '}
          <Link to="/contact-us">Let us know!</Link>
        </T.P>
      </Wrapper>
    </>
  );
};

export default Pricing;
